import React from 'react'
import { Helmet } from 'react-helmet'
import { PageContextProvider } from './src/pageContext'
import Loader from './src/components/Loader'
import logo from './src/images/logo.png'
import './src/main.scss'

export const wrapPageElement = ({ element }) => {
  return (
    <>
      <Helmet>
        <title>Akta Analytics</title>
        <link rel="icon" type="image/png" href={logo} sizes="16x16" />
      </Helmet>
      <PageContextProvider>
        <Loader />
        <main className="pageContent">{element}</main>
      </PageContextProvider>
    </>
  )
}
