import React from 'react'
import PropTypes from 'prop-types'
import './cta.scss'

const CTA = ({ type, onClick, height, width, size, children }) => {
  return (
    <button
      className={type}
      onClick={onClick}
      role="button"
      style={{ height: height, width: width, fontSize: size }}
    >
      {children}
    </button>
  )
}

CTA.propTypes = {
  type: PropTypes.oneOf([
    'primary',
    'secondary',
    'primary-transparent',
    'matte-black',
    'secondary-v1',
  ]),
  onClick: PropTypes.func,
  height: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
}

CTA.defaultProps = {
  type: 'primary',
  onClick: () => { },
  height: '6vh',
  width: '8vw',
  size: '1.1vw',
}

export default CTA
