import React, { useContext } from 'react'
import { Link, navigate } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { AuthContext } from '../../pageContext'

const NavMobile = () => {
  const { state, actions } = useContext(AuthContext)

  const handleLogout = () => {
    actions.logout()
    navigate('/')
  }

  return (
    <ul className="mobile">
      <li>
        <Link className="a" to="/">
          Home
        </Link>
      </li>
      <li>
        <div className="dropdown">
          {/*  <button className="dropbtn">
            <div className="dropbtn-text">Solutions</div>
            <FontAwesomeIcon icon={faCaretDown} />
          </button> */}
          <Link className="a" to="/solutions/">
            Solutions
          </Link>
          {/* <div className="dropdown-content">
            <Link className="a" to="/solutions/data">
              Data
            </Link>
            <Link className="a" to="/solutions/tech">
              Tech
            </Link>
            <Link className="a" to="/solutions/marketing">
              Marketing
            </Link>
          </div> */}
        </div>
      </li>
      <li>
        <Link className="a" to="/surveys/">
          Surveys
        </Link>
      </li>
      <li>
        <div className="dropdown">
          <button className="dropbtn">
            <div className="dropbtn-text">Resources</div>
            <FontAwesomeIcon icon={faCaretDown} />
          </button>
          <div className="dropdown-content">
            <Link to="/blog">Blog</Link>
            <Link to="/works">Works</Link>
            <Link to="/reports">Reports</Link>
            <Link to="/reports">
              Reports
            </Link>
          </div>
        </div>
      </li>
      <li>
        <Link className="a" to="/sample-size-calc">
          Sample Size Calculator
        </Link>
      </li>
      <li>
        <Link className="a" to="/contact">
          Contact Us
        </Link>
      </li>
      {state.isLoggedIn ? (
        <li>
          <button className="signout" onClick={handleLogout}>
            Log Out
          </button>
        </li>
      ) : (
        <li>
          <div className="dropdown">
            <button className="dropbtn">
              <div className="dropbtn-text">Login</div>
              <FontAwesomeIcon icon={faCaretDown} />
            </button>
            <div className="dropdown-content">
              <Link to="/auth?sign-in">Sign In</Link>
              <Link to="/auth?sign-up">Sign Up</Link>
            </div>
          </div>
        </li>
      )}
    </ul>
  )
}

export default NavMobile
