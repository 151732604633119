import React, { useState, useContext } from 'react'
import { Link, navigate } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import NavMain from './NavMain'
import NavMobile from './NavMobile'
import CTA from '../CTA'
import Logo from '../../images/logo.png'
import { AuthContext } from '../../pageContext'
import './navbar.scss'

const NavBar = () => {
  const [clickedHamburger, setClickedHamburger] = useState(false)
  const { state, actions } = useContext(AuthContext)

  const handleLogout = () => {
    actions.logout()
    navigate('/')
  }

  const handleClickedHamburger = () => {
    setClickedHamburger(!clickedHamburger)
  }

  return (
    <div className="header">
      <div className="nav" onClick={(e) => e.stopPropagation()}>
        <button className="nav-logo">
          <Link className="a" to="/">
            <img src={Logo} />
          </Link>
        </button>
        <NavMain clickedHamburger={clickedHamburger} />

        <div className="navWrapper">
          {clickedHamburger && <NavMobile />}
          <div className="authButtonsWrapper">
            {!state.isLoggedIn ? (
              <>
                <Link
                  className="a"
                  to="/auth?sign-in"
                  style={{ marginRight: '1.2vw' }}
                >
                  <CTA width={`5vw`} size={`16px`} height={`4vh`}>Login</CTA>
                </Link>
                <Link className="a" to="/auth?sign-up">
                  <CTA type="secondary" width={`5vw`} size={`16px`} height={`4vh`}>Sign Up</CTA>
                </Link>
              </>
            ) : (
              <CTA className="signout" type="secondary" onClick={handleLogout}>
                Log Out
              </CTA>
            )}
          </div>
        </div>

        <button className="hamburger" onClick={handleClickedHamburger}>
          <FontAwesomeIcon
            icon={!clickedHamburger ? faBars : faTimes}
            inverse
            fixedWidth
            size="3x"
          />
        </button>
      </div>
    </div>
  )
}

export default NavBar
