import { faAngleDown, faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLocation } from '@reach/router'
import { Link, navigate } from 'gatsby'
import React, { useContext, useState } from 'react'
import { AuthContext } from '../../pageContext'

const NavMain = () => {
  const { state, actions } = useContext(AuthContext)
  const [active, setActive] = useState('Home')
  const location = useLocation()

  const { pathname } = location

  const handleLogout = () => {
    actions.logout()
    navigate('/')
  }

  const handleNavClick = (e) => {
    setActive(e.target.innerHTML)
  }

  const ENUM = {
    home: 'Home',
    surveys: 'Surveys',
    reports: 'Reports',
    pricing: 'Pricing',
    sample: 'Sample Size Calculator',
    contact: 'Contact Us',
    solutions: 'Solution',
    resources: 'Resources',
  }

  return (
    <ul className="main">
      <li>
        <Link className="a" to="/">
          <button
            className={
              active === ENUM.home ? 'activeNavButton navButton' : 'navButton'
            }
            onClick={handleNavClick}
          >
            Home
          </button>
        </Link>
      </li>
      <li>
        {!pathname.includes('respondent') ? (
          <Link className="a" to="/solutions">
            <button
              className={
                active === ENUM.solutions
                  ? 'activeNavButton navButton'
                  : 'navButton'
              }
              onClick={handleNavClick}
            >
              Solutions
            </button>
          </Link>
        ) : (
          ''
        )}
      </li>
      <li>
        <Link className="a" to="/surveys/">
          <button
            className={
              active === ENUM.surveys
                ? 'activeNavButton navButton'
                : 'navButton'
            }
            onClick={handleNavClick}
          >
            Surveys
          </button>
        </Link>
      </li>
      {/*  <li>
        <Link className="a" to="/pricing/">
          <button
            className={
              active === ENUM.pricing
                ? 'activeNavButton navButton'
                : 'navButton'
            }
            onClick={handleNavClick}
          >
            Pricing
          </button>
        </Link>
      </li> */}

      {!pathname.includes('respondent') ? (
        <>
          <li>
            <div className="dropdown">
              <button className="dropbtn">
                <div className=" navButton">Resources </div>
                <FontAwesomeIcon className="ml-4" icon={faAngleDown} />
              </button>
              <div className="dropdown-content">
                <Link className="a" to="/blog">
                  Blog
                </Link>
                <Link className="a" to="/works">
                  Works
                </Link>
                <Link className="a" to="/reports">
                  <button
                    className={
                      active === ENUM.resources
                        ? 'activeNavButton navButton a'
                        : 'navButton a'
                    }
                    onClick={handleNavClick}
                  >
                    Reports
                  </button>
                </Link>
                <Link to="/sample-size-calc">
                  <button
                    className={
                      active === ENUM.sample
                        ? 'a activeNavButton navButton'
                        : 'a navButton'
                    }
                    onClick={handleNavClick}
                  >
                    Calculator
                  </button>
                </Link>
              </div>
            </div>
          </li>
        </>
      ) : (
        ''
      )}
      <li>
        <Link to="/contact">
          <button
            className={
              active === ENUM.contact
                ? 'activeNavButton navButton'
                : 'navButton'
            }
            onClick={handleNavClick}
          >
            Contact Us
          </button>
        </Link>
      </li>
      {/* {state.isLoggedIn ? (
        <li>
          <button className="signout" onClick={handleLogout}>
            SIGN OUT
          </button>
        </li>
      ) : (
        <li>
          <div className="dropdown">
            <button className="dropbtn">
              <div className="dropbtn-text">LOGIN</div>
              <FontAwesomeIcon icon={faCaretDown} />
            </button>
            <div className="dropdown-content">
              <Link to="/auth?sign-in">SIGN IN</Link>
              <Link to="/auth?sign-up">SIGN UP</Link>
            </div>
          </div>
        </li>
      )} */}
    </ul>
  )
}

export default NavMain
