import React, { createContext, useCallback, useEffect, useState } from 'react'
import NavBar from './components/NavBar'
import axios from 'axios'

export const AuthContext = createContext({ state: {}, actions: {} })

export const PageContextProvider = ({ children }) => {
  const [token, setToken] = useState(false)
  const [userId, setUserId] = useState(false)
  const [isLoading, setIsloading] = useState(false)
  const [userType, setUserType] = useState(undefined)
  const [completedProfile, setCompletedProfile] = useState(false)
  const [isFirstSignUp, setIsFirstSignUp] = useState(false)
  const [user, setUser] = useState(false)
  const [allSurveys, setAllSurveys] = useState([])
  const [activeSurveys, setActiveSurveys] = useState([])
  const [completedSurveys, setCompletedSurveys] = useState([])

  const login = useCallback((uid, token, user) => {
    setToken(token)
    setUserId(uid)
    setIsloading(false)
    setUser(user)

    const tokenExpirationDate = new Date(new Date().getTime() + 1000 * 60 * 60)

    localStorage.setItem(
      'userData',
      JSON.stringify({
        user: user,
        userId: uid,
        token: token,
        expiration: tokenExpirationDate,
      })
    )

    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
  }, [])

  const logout = useCallback(() => {
    setToken(null)
    setUserId(null)
    localStorage.removeItem('userData')
    localStorage.removeItem('profileData')
    let token = null
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
  }, [])

  const authValue = {
    state: {
      token,
      userId,
      isLoggedIn: !!token,
      user,
      isFirstSignUp,
      activeSurveys,
      completedSurveys,
      allSurveys,
      userType,
      isLoading,
      completedProfile,
    },
    actions: {
      login,
      logout,
      setUser,
      setIsFirstSignUp,
      setCompletedSurveys,
      setActiveSurveys,
      setAllSurveys,
      setUserType,
      setIsloading,
      setCompletedProfile,
    },
  }

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('userData'))
    setIsloading(false)
    if (
      storedData &&
      storedData.token &&
      new Date(storedData.expiration) > new Date()
    ) {
      login(
        storedData.userId,
        storedData.token,
        storedData.user,
        new Date(storedData.expiration)
      )
    }
  }, [login])

  return (
    <AuthContext.Provider value={authValue}>
      <NavBar />
      {children}
    </AuthContext.Provider>
  )
}

export const usePageContext = () => React.useContext(AuthContext)
