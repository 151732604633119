import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { AuthContext } from '../../pageContext'
import './loader.scss'

const Loader = () => {
  const { state } = useContext(AuthContext)
  return (
    <>
      {state.isLoading && (
        <div className="spinnerContainer">
          <FontAwesomeIcon
            icon={faSpinner}
            fixedWidth
            size="5x"
            spin
            color="white"
            className="spinner"
          />
        </div>
      )}
    </>
  )
}

export default Loader
